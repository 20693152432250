<template lang="pug">
page-structure-element(
  v-if="getPoweredBy && getPoweredBy.visible"
  @clicked="click"
  :isPoweredBy="true"
) {{ getPoweredBy.text }}
</template>

<script>
  import pageStructureMixin from '@/editor/mixins/sidebar/pageStructure';
  import featureForFeedbackMixin from '@/editor/mixins/featureForFeedback';
  import PageStructureElement from '../PageStructureElement.vue';

  export default {
    components: { PageStructureElement },
    mixins: [pageStructureMixin, featureForFeedbackMixin],

    methods: {
      click() {
        if (this.isRemovePoweredByRequestModalShowable) {
          this.clickHandler(this.location.LAYERS, this.requestType.FOR_REVIEW);
        } else if (this.isPageViewBasedPackage) {
          this.clickHandler(this.location.LAYERS, this.requestType.UPGRADE);
        } else {
          this.clickHandler(this.location.LAYERS, this.requestType.NONE);
        }
      },
    },
  };
</script>
